import React from 'react'

import Layout from "../../components/layout"

import locales from '../../utils/strings';
import people from '../../../content/people.yml';

import styles from '../../templates/basic.module.less';
import localStyles from './ob.module.less';

// Find the set of displayed titles
const displayedTitles = {};
for (let titleGroup of people.titles) {
  for (let title of titleGroup) {
    displayedTitles[title] = true;
  }
}

// Validate the people list
for (let person of people.members) {
  if (!displayedTitles[person.title]) {
    throw new Error(`The person '${person.name}'' has an unrecognized title: '${person.title}'`);
  }
}

const FormerMembersPage = ({ location, pathContext }) => {
  const { lang } = pathContext;

  if (!locales[lang]) {
    throw new Error(`Unknown language: ${lang}.`);
  }

  const strings = locales[lang].formerMembers;

  return (
    <Layout location={location}>
      <h1 className={styles.title}>{strings.title}</h1>
      {
        people.formerMembers.map((yearInfo, i) => <section key={i}>
          <h2>{strings.yearHeading.replace('{}', yearInfo.year)}</h2>
          <dl className={localStyles.titleGroup}>
            {
              people.formerMemberTitles.map((title, i) =>
                <MembersForTitle
                  key={i}
                  lang={lang}
                  strings={strings}
                  title={title}
                  people={yearInfo.people} />)
            }
          </dl>
        </section>)
      }
    </Layout>
  );
};

const MembersForTitle = ({ lang, strings, title, people }) => {
  const titleName = strings.memberTitleSectionTitle[title];
  if (!titleName) {
    throw new Error(`The title name '${title}' doesn't have a translation`);
  }

  const peopleWithTheTitle = people.filter(p => p.title === title);
  if (peopleWithTheTitle.length === 0) {
    return null;
  }

  return <div>
    <dt>{titleName}</dt>
    <dd><ul>
      {
        peopleWithTheTitle.map((p, i) =>
          lang === 'en' ?
            <li key={i}><MemberLink lang={lang} href={p.href}>{p.englishName}</MemberLink></li>
          :
            <li key={i}><MemberLink lang={lang} href={p.href}>{p.name}</MemberLink> ({p.englishName})</li>
        )
      }
    </ul></dd>
  </div>;
};

const MemberLink = ({ children, href, lang }) =>
  href ?
    <a href={typeof href === 'object' ? href[lang] : href}>{children}</a>
  :
    <span>{children}</span>

export default FormerMembersPage
